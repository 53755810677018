<template>
	<div
		data-component="actions"
		:data-wrapped="isWrapped"
		v-if="getHasActions"
	>
		<div
			data-element="actions-primary"
			v-if="getPrimaryActions"
		>
			<btn
				v-for="(btn, key) in getPrimaryActions"
				:key="key"
				v-bind="btn"
				:size="size"
				:class="[btn.variant ? btn.variant : 'filled']"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
				:isDisabled="isDisabled"
			/>
		</div>
		<div
			data-element="actions-secondary"
			v-if="getSecondaryActions"
		>
			<btn
				v-for="(btn, key) in getSecondaryActions"
				:key="key"
				v-bind="btn"
				:size="size"
				:class="[btn.variant ? btn.variant : 'filled']"
				@actionClick="onActionClick"
				:isWaiting="isWaiting"
				:isDisabled="isDisabled"
			/>
		</div>
	</div>
</template>

<script>
	import Btn from '@/components/ui/Btn.vue';

	export default {
		components: {
			Btn
		},
		props: {
			actions: {
				type: Object,
				default: () => {}
			},
			// TODO: Better loading spinner
			// change this to string
			// the value should be related to the button which was clicked
			// the clicked button should show the spinner and be inactive
			// all other buttons should be inactive
			isWaiting: {
				type: String,
				default: undefined
			},
			isDisabled: {
				type: String,
				default: undefined
			},
			size: {
				type: String,
				default: 'md'
			},
			isWrapped: {
				type: Boolean,
				default: false
			}
		},
		computed: {
			getHasActions () {
				if (!this.getPrimaryActions &&
						!this.getSecondaryActions) {
					return false;
				}
				return true;
			},
			getPrimaryActions () {
				if (!this.actions ||
						!this.actions.primary ||
						!this.actions.primary.length) {
					return false;
				}
				return this.actions.primary;
			},
			getSecondaryActions () {
				if (!this.actions ||
						!this.actions.secondary ||
						!this.actions.secondary.length) {
					return false;
				}
				return this.actions.secondary;
			}
		},
		methods: {
			onActionClick (actionId) {
				if (!actionId) {
					return;
				}
				this.$emit('actionClick', actionId);
			}
		}
	};

</script>

<style lang="scss" scoped>

	[data-component='actions'] {
		display:flex;
		justify-content:space-between;
		padding:rem(20) 0 rem(30);

    [data-component='btn'] {
      border-radius: 4px;
      @include font(16px, $c-dark-navy, 500, 20px);
      padding: 8px 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      border: 1px solid transparent;

      &:hover:not(.disabled) {
        filter: brightness(80%);
      }

      &.disabled {
        opacity: 0.75;
        cursor: not-allowed;
      }

      &.fit {
        width: fit-content;
      }

      &.red {
        background: $c-red;
        color: $c-white-l;
        border: 1px solid $c-red;
      }

      &.outline {
        background: $c-bg-white;
        color: $c-dark-navy;
        border: 1px solid $c-dark-navy;
      }

      &.filled {
        background: $c-dark-navy;
        color: $c-white-l;
        border: 1px solid $c-dark-navy;
      }

      &.text {
        color: $c-dark-navy;
        background: none;
        text-align: center;

        &:hover:not(.disabled) {
          background: $c-gray-l2;
          border: 1px solid $c-gray-l2;
        }
      }

      &.correct {
        background: $c-green-l2;
        color: $c-darkest-navy;
        border: 1px solid $c-green-l2;
      }

      &.incorrect {
        background: $c-red-l2;
        color: $c-darkest-navy;
        border: 1px solid $c-red-l2;
      }

    }

		[data-element='actions-primary'] {
			display:flex;
			align-items:flex-start;
			margin:0 auto 0 0;
			[data-component='btn'] {
				margin-right:rem(16);
			}
		}
		[data-element='actions-secondary'] {
			display:flex;
			align-items:flex-end;
			margin:0 0 0 auto;
			[data-component='btn'] {
				margin-left:rem(16);
			}
		}
		&[data-wrapped] {
			[data-element='actions-primary'],
			[data-element='actions-secondary'] {
				flex-wrap: wrap;
				[data-component='btn'] {
					margin-bottom: rem(16);
				}
			}
		}
	}

</style>
